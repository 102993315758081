import { Badge } from "design-react-kit";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import { getSingleNews } from "../../redux/actions/core";

export default function NewsSinglePage() {
  const params = useParams();
  const id = params?.idNews;
  const dispatch = useDispatch();
  const singleNews = useSelector((state) => state.core.homePageSingleNews);

  const dateValue = (date) => {
    if (date) {
      let newDate = new Date(date);
      let options = {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return newDate.toLocaleDateString("it-IT", options);
    } else return "";
  };

  useEffect(() => {
    //console.log(id);
    dispatch(getSingleNews(id));
  }, []);

  return (
    <div>
      <div className="pb-5" style={{ minHeight: "60vh" }}>
        <div>
          <div className="container">
            <BreadcrumbConsip
              pages={[
                { label: "News", url: "/news" },
                { label: "No." + id, url: `/news/${id}` },
              ]}
            />
          </div>
        </div>
        <div className="container">
          {singleNews ? (
            <div className="row">
              <div className="col-12 text-left">
                <h3>{singleNews?.title}</h3>
              </div>
              <div className="col-12 px-3">
                <div className="row">
                  <div className="col-12 text-left pb-5">
                    <Badge color="primary" pill>
                      {singleNews?.category}
                    </Badge>
                  </div>
                  <div className="col-12 text-center py-1">
                    {/* <img
                      src={singleNews?.url ? singleNews?.url : ""}
                      style={{ maxHeight: "300px" }}
                      alt="news"
                    /> */}
                  </div>
                  <div className="col-12 text-left pb-5">
                    <p>{singleNews?.body}</p>
                  </div>
                </div>
              </div>
              <div className="col-12 text-right">
                {dateValue(singleNews?.date)}
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
