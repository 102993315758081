import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import { getContractDetails } from "../../redux/actions/documents";
import ManagementPage from "./components/ManagementPage";
import TablePagination from "./components/TablePagination";

export default function ExecutivesPage() {
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const params = useParams();
  const id = params?.id;
  const mode = params?.mode;
  const dispatch = useDispatch();

  const contractDetailsSpecial = useSelector(
    (state) => state.documents.contractDetailsSpecial
  );

  const calculateTime = (end, start) => {
    const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
    const lastDate = end !== undefined ? new Date(end) : new Date();
    const firstDate = new Date(start);
    return Math.round(Math.abs((lastDate - firstDate) / oneDay));
  };

  const [modeTitle, setModeTitle] = useState("");

  useEffect(() => {
    if (!user?.user) history.push("/login");
    else {
      let idArr = id.split("_");
      let idValue = idArr.length > 1 ? idArr[1] : -1;
      dispatch(getContractDetails(idValue, user?.user?.sub));
      switch (mode) {
        case "plan":
          setModeTitle("Piano del Fabbisogni");
          break;
        case "project":
          setModeTitle("Piano Operativo");
          break;
        case "contract":
          setModeTitle("Contratto Esecutivo");
          break;
        case "management":
          setModeTitle("Gestione Progettuale");
          break;
        default:
          return "";
      }
    }
  }, []);

  return (
    <div className="pb-4" style={{ minHeight: "60vh" }}>
      <div>
        <div className="container">
          <BreadcrumbConsip
            pages={[
              { label: "Project Management", url: "/projects" },
              { label: id?.replaceAll("-", "/"), url: `/projects/${id}` },
              { label: modeTitle, url: `/projects/${id}` },
            ]}
          />
        </div>
      </div>
      <div className="container">
        <div className="text-left">
          <h3>Contratto Esecutivo n.{id?.replaceAll("-", "/")}</h3>
        </div>
      </div>
      <div className="container px-3">
        <div className="row ">
          <div className="col-12 py-4">
            <div className="container">
              <div className="text-left">
                <h5>{modeTitle}</h5>
              </div>
            </div>
            <div className="container px-3 pt-3">
              <div className="text-left">
                {modeTitle === "Piano Operativo"
                  ? contractDetailsSpecial?.pianooperativo?.length > 0
                    ? contractDetailsSpecial?.pianodelfabbisogni?.length > 0
                      ? "Tempo Trascorso per erogazione Piano Operativo: " +
                        calculateTime(
                          contractDetailsSpecial?.pianooperativo[
                            contractDetailsSpecial?.pianooperativo?.length - 1
                          ]?.startData,
                          contractDetailsSpecial?.pianodelfabbisogni[
                            contractDetailsSpecial?.pianodelfabbisogni?.length -
                              1
                          ]?.startData
                        )
                      : ""
                    : contractDetailsSpecial?.pianodelfabbisogni?.length > 0
                    ? "Tempo Trascorso dalla richiesta del Piano Operativo: " +
                      calculateTime(
                        undefined,
                        contractDetailsSpecial?.pianodelfabbisogni[
                          contractDetailsSpecial?.pianodelfabbisogni.length - 1
                        ]?.startData
                      )
                    : ""
                  : modeTitle === "Contratto Esecutivo"
                  ? contractDetailsSpecial?.contrattoesecutivo?.length > 0
                    ? contractDetailsSpecial?.pianooperativo?.length > 0
                      ? "Tempo Trascorso per erogazione Contratto Esecutivo: " +
                        calculateTime(
                          contractDetailsSpecial?.contrattoesecutivo[
                            contractDetailsSpecial?.contrattoesecutivo?.length -
                              1
                          ]?.startData,
                          contractDetailsSpecial?.pianooperativo[
                            contractDetailsSpecial?.pianooperativo.length - 1
                          ]?.startData
                        )
                      : ""
                    : contractDetailsSpecial?.pianooperativo?.length > 0
                    ? "Tempo Trascorso dalla richiesta del Contratto Esecutivo: " +
                      calculateTime(
                        undefined,
                        contractDetailsSpecial?.pianooperativo[
                          contractDetailsSpecial?.pianooperativo.length - 1
                        ]?.startData
                      )
                    : ""
                  : ""}
              </div>
            </div>
            {mode === "management" ? (
              <ManagementPage
                data={contractDetailsSpecial?.gestioneprogettuale}
              ></ManagementPage>
            ) : (mode === "plan" &&
                contractDetailsSpecial?.pianodelfabbisogni?.length > 0) ||
              (mode === "project" &&
                contractDetailsSpecial?.pianooperativo?.length > 0) ||
              (mode === "contract" &&
                contractDetailsSpecial?.contrattoesecutivo?.length > 0) ? (
              <TablePagination
                data={
                  mode === "plan"
                    ? contractDetailsSpecial?.pianodelfabbisogni
                    : mode === "project"
                    ? contractDetailsSpecial?.pianooperativo
                    : mode === "contract"
                    ? contractDetailsSpecial?.contrattoesecutivo
                    : []
                }
              ></TablePagination>
            ) : (
              <div className="p-3 text-left">
                Non ci sono Documenti inseriti per questa sezione
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
