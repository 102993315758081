const initialState = {
  loading: false, //if true show a loading spinner
  refreshState: false,
  executive_id: null,
  contracts: undefined,
};

const projectManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_CONTRACTS":
      return {
        ...state,
        contracts: action.payload,
      };
    case "SET_REFRESH_STATE":
      return {
        ...state,
        refreshState: action.payload,
      };
    case "SET_FILTERS":
      return {
        ...state,
        filters: action.payload,
      };
    case "SET_MONITORING_DETAILS":
      return {
        ...state,
        monitoring_details: action.payload,
      };
    case "SET_SLA":
      return {
        ...state,
        sla: action.payload,
      };
    case "SET_INDEXES":
      return {
        ...state,
        indexes: action.payload,
      };
    case "SET_TIPOLOGIA":
      return {
        ...state,
        tipologia: action.payload,
      };
    case "SET_ECOSISTEM":
      return {
        ...state,
        ecosistem: action.payload,
      };
    default:
      return state;

  }
};

export default projectManagementReducer;
