import React, { useEffect, useState } from "react";
import PaginationConsip from "./PaginationConsip";

export default function TablePagination({ data }) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(1);
  const numberElemPage = 5;

  useEffect(() => {
    if (data !== undefined) setTotal(Math.ceil(data?.length / numberElemPage));
  }, []);
  return (
    <div className="container px-3">
      <div
        style={
          data?.length > 5 ? { minHeight: "350px" } : { marginBottom: "40px" }
        }
      >
        <div
          className="row text-left px-4 py-3"
          style={{
            backgroundColor: "#e6e9f2",
          }}
        >
          <div className="col-12">
            {data !== undefined &&
              data.map((elem, index) => {
                if (
                  index + 1 <= page * numberElemPage &&
                  index + 1 > (page - 1) * numberElemPage
                )
                  return (
                    <div
                      className="col-12 p-2 my-3"
                      style={{
                        backgroundColor: "white",
                        minHeight: "10px",
                        maxHeight: "10px",
                      }}
                    >
                      {elem.url ? (
                        elem.startData ? (
                          <div className="row">
                            <div className="col-12 col-sm-8 align-self-center">
                              <a
                                href={elem.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                {elem.title}
                              </a>
                            </div>
                            <div className="col-12 col-sm-4 text-center align-self-center py-2">
                              {elem.startData.split("T")[0]}
                            </div>
                          </div>
                        ) : (
                          <a
                            href={elem.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {elem.title}
                          </a>
                        )
                      ) : (
                        elem.title
                      )}
                    </div>
                  );
              })}
          </div>
        </div>
      </div>

      {total > 1 && (
        <div className="row justify-content-center py-2">
          <div className="">
            <PaginationConsip
              totalPage={total}
              currentPage={page}
              setPage={setPage}
            ></PaginationConsip>
          </div>
        </div>
      )}
    </div>
  );
}
