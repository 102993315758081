import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { getAdmin } from "../../redux/actions/core";
import { insertDocuments } from "../../redux/actions/documents";
import { getSchemaObject } from "../../utility/functions/functions";
import "./AdminSection.css";

export default function AdminSection() {
  const initialState = {
    contractCode: "",
    section: "",
    datetime: "",
    file: "",
  };
  const [file, setFile] = useState(undefined);
  const [datetime, setDatetime] = useState(undefined);

  const dispatch = useDispatch();
  let history = useHistory();
  const superAdmin = useSelector((state) => state.core.superAdmin);
  const section = useSelector((state) => state.core.section);
  const user = useSelector((state) => state.user);

  const handleFormikSubmit = (values) => {
    //console.log(values, file, datetime);
    let idSection = values.section.split("|");
    if (idSection[1] !== "")
      dispatch(
        insertDocuments({
          contractID: values.contractCode,
          sectionID: idSection[0],
          subSectionID: idSection[1],
          encodedFile: file.base64,
          fileName: file.name,
          startDate: datetime,
        })
      );
    else
      dispatch(
        insertDocuments({
          contractID: values.contractCode,
          sectionID: idSection[0],
          encodedFile: file.base64,
          fileName: file.name,
          startDate: datetime,
        })
      );
  };

  const handleDate = (event, setFieldValue) => {
    //let date;
    if (event.target.value) {
      //date = new Date(event.target.value);
      setFieldValue("datetime", event.target.value);
      setDatetime(event.target.value);
    } else {
      setFieldValue("datetime", "");
      setDatetime(undefined);
    }
  };

  const handleFile = async (event, setFieldValue) => {
    //console.log(event.target.files[0])
    const file = event.target.files[0];
    try {
      let resultBase = await getBase64(file);
      file["base64"] = resultBase;
      file["base64"] = file.base64.split("base64,")[1];
      //this.props.attachmentQuoteRequestAction(this.props.quoteRequest, file.name, file.base64)
      //console.log("File Is", file);
      setFile(file);
      setFieldValue("file", file.name);
    } catch (e) {
      //console.log("error base64 -", e);
      setFile(undefined);
      setFieldValue("file", "");
    }
    // event.target.value = null
  };

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      // Make new FileReader
      let reader = new FileReader();
      // Convert the file to base64 text
      reader.readAsDataURL(file);
      // on reader load something...
      reader.onload = () => {
        // Make a fileInfo Object
        //console.log("Called", reader);
        baseURL = reader.result;
        //console.log(baseURL);
        resolve(baseURL);
      };
      //console.log(fileInfo);
    });
  };

  useEffect(() => {
    if (user.user?.sub) dispatch(getAdmin(user.user?.sub));
    else history.push("/");
  }, []);

  return (
    <>
      <div className="pb-5" style={{ minHeight: "60vh" }}>
        <div className="container">
          <div className="text-left pb-3">
            <h3>Admin Page</h3>
          </div>
          {superAdmin ? (
            <div className="row">
              <div className="col-12 text-left pb-5">
                <Formik
                  enableReinitialize={false}
                  // validateOnBlur={false}
                  initialValues={initialState}
                  validationSchema={getSchemaObject([
                    "contractCode",
                    "section",
                    "file",
                    "datetime",
                  ])}
                  onSubmit={(values) => handleFormikSubmit(values)}
                >
                  {({
                    errors,
                    touched,
                    values,
                    handleChange,
                    handleBlur,
                    setFieldValue,
                  }) => {
                    return (
                      <Form class="px-2">
                        <FormGroup className="position-relative">
                          <input
                            className={`w-50 ${
                              errors.contractCode && touched.contractCode
                                ? "is-invalid"
                                : ""
                            }`}
                            autoComplete="off"
                            type="contractCode"
                            placeholder={"Codice Contratto"}
                            name="contractCode"
                            id="contractCode"
                            defaultValue={values.contractCode}
                            onBlur={(e) => {
                              handleBlur(e);
                              handleChange(e);
                            }}
                          />
                          <Label className="form-label" for="contractCode">
                            Codice Contratto
                          </Label>
                          {errors.contractCode && touched.contractCode ? (
                            <FormFeedback>{errors.contractCode}</FormFeedback>
                          ) : null}
                        </FormGroup>
                        <FormGroup className="position-relative">
                          <Field
                            className={`w-50 ${
                              errors.section && touched.section
                                ? "is-invalid"
                                : ""
                            }`}
                            as="select"
                            placeholder={"Sezione"}
                            name="section"
                            id="section"
                          >
                            <option value=""></option>
                            {section?.map((el, i) => (
                              <option
                                key={i}
                                value={
                                  el.id_document_section +
                                  "|" +
                                  el.id_sub_section
                                }
                              >
                                {el.sectionName}
                              </option>
                            ))}
                          </Field>
                          <Label className="form-label" for="section">
                            Sezione
                          </Label>
                          {errors.section && touched.section ? (
                            <FormFeedback>{errors.section}</FormFeedback>
                          ) : null}
                        </FormGroup>
                        <FormGroup className="position-relative">
                          <Input
                            className={`w-50 ${
                              errors.datetime && touched.datetime
                                ? "is-invalid"
                                : ""
                            }`}
                            type="date"
                            name="datetime"
                            id="datetime"
                            onKeyDown={(e) => e.preventDefault()}
                            onChange={(e) => handleDate(e, setFieldValue)}
                            placeholder="datetime placeholder"
                          />
                          <Label className="form-label" for="datetime">
                            Data Completamento
                          </Label>
                          {errors.datetime && touched.datetime ? (
                            <FormFeedback>{errors.datetime}</FormFeedback>
                          ) : null}
                        </FormGroup>
                        <FormGroup className="position-relative">
                          <input
                            className={`w-50 ${
                              errors.file && touched.file ? "is-invalid" : ""
                            }`}
                            type="file"
                            name="file"
                            id="file"
                            onChange={(e) => handleFile(e, setFieldValue)}
                          />
                          <Label className="form-label" for="file">
                            File
                          </Label>
                          {errors.file && touched.file ? (
                            <FormFeedback>{errors.file}</FormFeedback>
                          ) : null}
                        </FormGroup>
                        <button type="submit" class="btn btn-primary">
                          {" "}
                          Invia
                        </button>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          ) : (
            <div className="row"></div>
          )}
        </div>
      </div>
    </>
  );
}
