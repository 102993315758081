import React from "react";
import TablePagination from "./TablePagination";

export default function ManagementPage({ data }) {
  return (
    <div className="container py-4">
      {data !== undefined ? (
        <div className="row">
          {data?.pianodellaqualità && (
            <>
              <div className="col-12 text-left">
                <h6>Piano della Qualità</h6>
              </div>
              <div className="col-12">
                <TablePagination
                  data={data !== undefined ? data?.pianodellaqualità : []}
                ></TablePagination>
              </div>
            </>
          )}
          {data?.masterplan && (
            <>
              <div className="col-12 text-left">
                <h6>Masterplan</h6>
              </div>
              <div className="col-12">
                <TablePagination
                  data={data !== undefined ? data?.masterplan : []}
                ></TablePagination>
              </div>
            </>
          )}
          {data?.salecomunicazioni && (
            <>
              <div className="col-12 text-left">
                <h6>SAL e Comunicazioni</h6>
              </div>
              <div className="col-12">
                <TablePagination
                  data={data !== undefined ? data?.salecomunicazioni : []}
                ></TablePagination>
              </div>
            </>
          )}

          {data["verbalediconformità/chiusuraintervento"] && (
            <>
              {" "}
              <div className="col-12 text-left">
                <h6>Verbale di Conformità / Chiusura intervento</h6>
              </div>
              <div className="col-12">
                <TablePagination
                  data={
                    data !== undefined
                      ? data["verbalediconformità/chiusuraintervento"]
                      : []
                  }
                ></TablePagination>
              </div>
            </>
          )}
          {data?.rilevazioni && (
            <>
              <div className="col-12 text-left">
                <h6>Rilevazioni</h6>
              </div>
              <div className="col-12">
                <TablePagination
                  data={data !== undefined ? data?.rilevazioni : []}
                ></TablePagination>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="row">
          <div className="col-12 text-left">
            <h6>Non ci sono Documenti Caricati</h6>
          </div>
        </div>
      )}
    </div>
  );
}
