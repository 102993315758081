import React from "react";
import { Icon, Badge } from "design-react-kit";
import moment from "moment";
import { Link } from "react-router-dom";

function ConsipNewsCard({ title, subtitle, date, url, id_news, category }) {
  return (
    <div className="card-wrapper text-left">
      <div className="card card-img no-after rounded-corners">
        {/* <img
          src={url ? url : ""}
          style={{ maxHeight: "250px", borderRadius: "15px" }}
          alt="news"
        /> */}
        <div className="card-body px-0">
          <div className="category-top">
            <Badge color="primary" pill>
              {category}
            </Badge>
            {/* <Link className="category" to={`/news/${id_news}`}>
              {category}
            </Link> */}
            <span className="data">{moment(date).format("DD/MM/YYYY")}</span>
          </div>
          <h5 className="card-title big-heading">{title}</h5>
          <p className="card-text">{subtitle}</p>
          {/* <span className="card-signature">di Gambuzza Giuseppe</span> */}
          <br></br>
          <Link className="read-more" to={`/news/${id_news}`}>
            <span className="text">Leggi di più</span>
            <Icon color="" icon="it-arrow-right" padding={false} size="" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ConsipNewsCard;
