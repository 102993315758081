import React from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";
import GuidePage from "../InfoPage/GuidePage";

export default function AQPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Accordo Quadro", url: "/aq" }]} />
      </div>
      <div className="pb-5">
        <div className="container">
          <div className="text-left pb-3">
            <h3>Accordo Quadro</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              <p>
                A seguito dell’aggiudicazione del Lotto 6 “Servizi di Supporto -
                PAL SUD” della “Gara a procedura aperta per l’affidamento di un
                Accordo Quadro per la fornitura di servizi cloud IaaS e PaaS in
                un modello di erogazione pubblico nonché per la prestazione di
                servizi connessi, servizi professionali di supporto all’adozione
                del cloud, servizi professionali tecnici per le Pubbliche
                Amministrazioni (ID2213)”, Consip S.p.A. ha stipulato un Accordo
                Quadro con il RTI Aggiudicatario, composto dalle aziende{" "}
                <b>EY Advisory Spa</b>, <b>Deloitte Consulting S.r.l.</b> e{" "}
                <b>Commedia S.r.l.</b>
              </p>
              <h6>SERVIZI</h6>
              <p>I servizi oggetto del Lotto in oggetto sono:</p>
              <ul>
                <li>Assessment (S1)</li>
                <li>Strategia di Migrazione (S2) </li>
                <li>Studio di Fattibilità (S3) </li>
                <li>PMO (S4) </li>
              </ul>
              <h6>CHI PUÒ ADERIRE</h6>
              <p>
                Gli Enti che possono aderire al presente Accordo Quadro sono le
                Pubbliche Amministrazioni Locali aventi sedi nelle seguenti
                regioni:
              </p>
              <ul>
                <li>Campania</li>
                <li>Molise</li>
                <li>Basilicata</li>
                <li>Calabria</li>
                <li>Puglia</li>
                <li>Sicilia</li>
              </ul>
              <p>ricomprese nelle seguenti tipologie:</p>
              <ul>
                <li>Regioni</li>
                <li>Province Autonome</li>
                <li>Province</li>
                <li>Città metropolitane</li>
                <li>Comuni</li>
                <li>Comunità montane</li>
                <li>Unioni di Comuni</li>
                <li>Istituti zooprofilattici sperimentali</li>
                <li>
                  Agenzie, enti e consorzi per il diritto allo studio
                  universitario
                </li>
                <li>Agenzie ed enti per il turismo</li>
                <li>Agenzie ed enti regionali del lavoro</li>
                <li>
                  Agenzie ed enti regionali e provinciali per la formazione, la
                  ricerca e l'ambiente
                </li>
                <li>Agenzie regionali per la rappresentanza negoziale</li>
                <li>Agenzie regionali per le erogazioni in agricoltura</li>
                <li>
                  Agenzie regionali sanitarie e aziende ed enti di supporto al
                  SSN
                </li>
                <li>
                  Enti di governo dei servizi idrici e/o dei rifiuti (ex AATO)
                </li>
                <li>Autorità di sistema portuale</li>
                <li>
                  Aziende ospedaliere, aziende ospedaliero-universitarie,
                  policlinici e istituti di ricovero e cura a carattere
                  scientifico pubblici
                </li>
                <li>Aziende sanitarie locali</li>
                <li>
                  Camere di commercio, industria, artigianato e agricoltura e
                  unioni regionali
                </li>
                <li>Consorzi di bacino imbrifero montano</li>
                <li>Consorzi tra amministrazioni locali</li>
                <li>
                  Parchi nazionali, consorzi ed enti gestori di parchi e aree
                  naturali protette
                </li>
                <li>Consorzi interuniversitari di ricerca</li>
                <li>Agenzie ed enti regionali di sviluppo agricolo</li>
                <li>Fondazioni lirico-sinfoniche</li>
                <li>Teatri nazionali e di rilevante interesse culturale</li>
                <li>
                  Università e istituti di istruzione universitaria pubblici
                </li>
                <li>Altre amministrazioni locali</li>
                <li>
                  Consorzi di funzione ed associazioni tra enti locali non
                  esercenti attività economiche
                </li>
                <li>Comunità isolane e di arcipelago</li>
                <li>Enti pubblici a carattere regionale o locale</li>
                <li>
                  ogni altra Amministrazione e/o Ente, di rilevanza regionale o
                  locale;
                </li>
                <li>
                  gli Organismi di diritto pubblico e le Società, partecipati,
                  anche indirettamente, dai soggetti di cui a tutti i punti
                  precedenti, qualificabili come stazioni appaltanti (in caso di
                  società partecipate da soggetti di tipologie diverse – es.
                  partecipati contestualmente da soggetti rientranti nella PAC e
                  da soggetti rientranti nella PAL – si intenderanno ricompresi
                  nella PAL, ai fini della presente iniziativa, gli Organismi di
                  diritto pubblico e le Società partecipati in misura
                  maggioritaria, anche indirettamente, dai soggetti di cui ai
                  precedenti punti
                </li>
              </ul>
            </div>
            <div className="col-12 text-left">
              <GuidePage />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
