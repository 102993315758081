import { Icon } from "design-react-kit";
import React, { useState } from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

const pdfPath =
  "https://consip-public-files.s3.eu-west-1.amazonaws.com/Join+Page/CapitolatoTecnicoGenerale.pdf";

export default function HowToJoinPage() {
  const [active, setActive] = useState("1");

  return (
    <div className="container" style={{ paddingBottom: "25vh" }}>
      <div className="">
        <BreadcrumbConsip
          pages={[{ label: "Come aderire", url: "/come-aderire" }]}
        />
      </div>
      <div className="row pb-2">
        <div className="col-12">
          <div className="text-left ">
            <h3>Modalità di adesione</h3>
            <p>
              Il processo di adesione all’Accordo Quadro prevede i 3 passi
              illustrati nel seguito. Per maggiori dettagli si faccia
              riferimento al par. 2.3 del{" "}
              <a href={pdfPath} target="_blank" rel="noopener noreferrer">
                Capitolato Tecnico Generale.
              </a>
            </p>
          </div>
        </div>
      </div>
      <div className="row pb-2 mx-5">
        <div className="col-4 text-center" onClick={(e) => "1"}>
          <div>
            <b>
              <img
                alt="icon"
                className="p-3"
                src={process.env.PUBLIC_URL + "/assets/icons/join-1.svg"}
              />
            </b>
          </div>
          <div>
            <b>Piano dei fabbisogni</b>
          </div>
        </div>
        <div className="col-4 text-center" onClick={(e) => setActive("2")}>
          <div className>
            <b>
              <img
                alt="icon"
                className="p-3"
                src={process.env.PUBLIC_URL + "/assets/icons/join-2.svg"}
              />
            </b>
          </div>
          <div>
            <b>Piano operativo</b>
          </div>
        </div>
        <div className="col-4 text-center" onClick={(e) => setActive("3")}>
          <div>
            <b>
              <img
                alt="icon"
                className="p-3"
                src={process.env.PUBLIC_URL + "/assets/icons/join-3.svg"}
              />
            </b>
          </div>
          <div>
            <b>Contratto esecutivo</b>
          </div>
        </div>
      </div>
      <div className="row mx-5">
        <div className="col-4" onClick={(e) => setActive("1")}>
          <div className="row justify-content-center py-2">
            <div
              style={
                active === "1"
                  ? {
                      height: "38px",
                      width: "38px",
                      borderRadius: "50%",
                      backgroundColor: "dodgerblue",
                    }
                  : {
                      height: "38px",
                      width: "38px",
                      borderRadius: "50%",
                      backgroundColor: "gainsboro",
                    }
              }
            ></div>
          </div>
          {active === "1" ? (
            <div className="col-12">
              <Icon
                color=""
                icon="it-arrow-down-triangle"
                padding={false}
                size=""
              />
            </div>
          ) : null}
        </div>
        <div className="col-4" onClick={(e) => setActive("2")}>
          <div className="row justify-content-center py-2">
            <div
              style={
                active === "2"
                  ? {
                      height: "38px",
                      width: "38px",
                      borderRadius: "50%",
                      backgroundColor: "dodgerblue",
                    }
                  : {
                      height: "38px",
                      width: "38px",
                      borderRadius: "50%",
                      backgroundColor: "gainsboro",
                    }
              }
            ></div>
          </div>
          {active === "2" ? (
            <div className="col-12">
              <Icon
                color=""
                icon="it-arrow-down-triangle"
                padding={false}
                size=""
              />
            </div>
          ) : null}
        </div>
        <div className="col-4" onClick={(e) => setActive("3")}>
          <div className="row justify-content-center py-2">
            <div
              style={
                active === "3"
                  ? {
                      height: "38px",
                      width: "38px",
                      borderRadius: "50%",
                      backgroundColor: "dodgerblue",
                    }
                  : {
                      height: "38px",
                      width: "38px",
                      borderRadius: "50%",
                      backgroundColor: "gainsboro",
                    }
              }
            ></div>
          </div>
          {active === "3" ? (
            <div className="col-12">
              <Icon
                color=""
                icon="it-arrow-down-triangle"
                padding={false}
                size=""
              />
            </div>
          ) : null}
        </div>
      </div>
      <div className="row  py-4" style={{ backgroundColor: "gainsboro" }}>
        <div className="col-12 px-5">
          <div className="row">
            {active === "1" && (
              <>
                <div
                  className="col-3 offset-1 text-left"
                  style={{ fontSize: "28px" }}
                >
                  <div>
                    <b>Piano dei fabbisogni</b>
                  </div>
                </div>
                <div className="col-7 text-left">
                  <div className="row">
                    <div className="col-12">
                      La Pubblica Amministrazione (PA) che intende aderire ai
                      servizi dell'accordo Quadro predispone un Piano dei
                      Fabbisogni con il supporto del fornitore (RTI): il Piano
                      dei Fabbisogni contiene (a titolo non esaustivo), per
                      ciascuna categoria di servizi, la descrizione delle
                      esigenze e indicazioni di tipo quantitativo di ciascun
                      servizio che la Pubblica Amministrazione intende
                      sottoscrivere. A valle della predisposizione la Pubblica
                      Amministrazione trasmette il Piano dei Fabbisogni al
                      Fornitore all'indirizzo PEC eyadvisory@legalmail.it e
                      contestualmente, a mezzo posta elettronica, alla Consip
                      SPA.
                    </div>
                  </div>
                </div>
              </>
            )}
            {active === "2" && (
              <>
                <div
                  className="col-3 offset-1 text-left"
                  style={{ fontSize: "28px" }}
                >
                  <div>
                    <b>Piano operativo</b>
                  </div>
                </div>
                <div className="col-7 text-left">
                  <div className="row"></div>
                  <div className="row">
                    <div className="col-12">
                      Il Fornitore elabora un Piano Operativo che dettaglia le
                      richieste della Pubblica Amministrazione contenute nel
                      Piano dei Fabbisogni e contiene la proposta
                      tecnico/economica per l'erogazione dei servizi. A valle
                      dell'elaborazione, entro un termine massimo di 15 giorni
                      lavorativi dall'invio del Piano dei Fabbisogni o dal
                      maggiore termine eventualmente indicato
                      dall'Amministrazione, il Fornitore invia il Piano
                      Operativo alla Pubblica amministrazione tramite PEC.
                    </div>
                  </div>
                </div>
              </>
            )}
            {active === "3" && (
              <>
                <div
                  className="col-3 offset-1 text-left"
                  style={{ fontSize: "28px" }}
                >
                  <div>
                    <b>Contratto esecutivo</b>
                  </div>
                </div>
                <div className="col-7 text-left">
                  <div className="row">
                    <div className="col-12">
                      La Pubblica Amministrazione, entro 30 giorni dalla
                      ricezione, ha la facoltà di approvare il Piano Operativo e
                      contestualmente all’approvazione stipulerà con il
                      Fornitore il Contratto Esecutivo, sulla base dell’apposito
                      schema allegato alla documentazione di gara.
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
