import React from "react";
import { BreadcrumbConsip } from "../../components/Breadcrumb";

export default function ServicesPage() {
  return (
    <>
      <div className="container">
        <BreadcrumbConsip pages={[{ label: "Chi Siamo", url: "/chi-siamo" }]} />
      </div>
      <div className="pb-5">
        <div className="container">
          <div className="text-left pb-3">
            <h3>Chi Siamo</h3>
          </div>
          <div className="row">
            <div className="col-12 text-left pb-3">
              <img
                // className="img img-fluid"
                src={"./assets/icons/EYLogo.png"}
                height={50}
                alt="EYLogo"
              />
              <p className="text-justify">
                EY è leader a livello globale nei servizi professionali di
                consulenza con un fatturato mondiale di oltre 40 miliardi di
                dollari e più di 260 mila professionisti. Combina la presenza
                locale con la dimensione internazionale: l’organizzazione è,
                infatti, basata su una rete di più di 150 Paesi e suddivisa in
                quattro aree (Americas, EMEIA, Japan e Asia-Pacific) e per i
                clienti, questa rete è garanzia di sinergie tecniche e
                geografiche nonché di continuo aggiornamento rispetto a trend ed
                evoluzioni tecnologiche e di mercato. EY è una società
                multidisciplinare in grado di offrire ai propri clienti un ampio
                spettro di servizi a valore aggiunto su tutti i temi oggetto
                dell’Accordo Quadro Consip, quali: IT Strategy & Governance,
                Digital & Cloud Transformation, Change Management, Digital
                Communication, ecc. . Grazie ai suoi oltre 4.000 professionisti
                in Italia, EY opera a favore delle più importanti Pubbliche
                Amministrazioni Centrali (INAIL, INPS, Ministero dell’Economia e
                delle Finanze, Ministero dell’Interno, Ministero del Lavoro e
                delle Politiche Sociali, Ministero dell’Istruzione, AgID,
                Presidenza del Consiglio dei Ministri, AGEA, ecc.) e locali
                (Regione Lazio, Regione Lombardia, Regione Calabria, Regione
                Campania, Comune di Roma, Comune di Firenze, ecc.). Tali
                esperienze hanno consentito a EY di diventare partner delle
                principali Pubbliche Amministrazioni nel loro percorso di
                evoluzione tecnologica e trasformazione digitale e di disporre
                di metodologie e soluzioni personalizzate, basate sulle
                dinamiche e sulle specificità del contesto della PA.
              </p>
              <br></br>
              <img
                className="img my-2"
                src={"./assets/icons/deloitteLogo.png"}
                height={30}
                alt="deloitte"
              />
              <p className="text-justify">
                Deloitte è il più grande network mondiale di servizi
                professionali (Consulting, Risk Advisory, Audit, Tax & Legal,
                Financial Advisory) per imprese e PA, con un fatturato di oltre
                46,2 miliardi di dollari nell’anno fiscale 2019 con una crescita
                del 10% rispetto all’anno precedente, e con più di{" "}
                <b>312.000 persone in oltre 150 Paesi</b>; anche in Italia
                Deloitte è il network con più dipendenti, oltre 7.000, e
                maggiori ricavi, 669 milioni di euro. Il Consulting di Deloitte
                è riconosciuto per la leadership indiscussa nei servizi di
                Strategy&Operation, Technology e Human Capital.{" "}
                <b>Deloitte Consulting</b> in Italia può contare più di 3.100
                professional, di cui oltre 1.500 nell’area Technology e fornisce
                servizi ai principali Gruppi finanziari ed industriali e alle
                principali PA Centrali, tra le quali vi sono l’Agenzia per
                l’Italia Digitale, l’INPS, ISTAT, vari Ministeri (Lavoro,
                Infrastrutture e Trasporti, ecc.), Enti Locali e relative
                società in-house (tra le quali Regione Lombardia, Lazio, Emilia
                Romagna, Veneto, Sicilia, Sardegna, ecc.). Tra i principali
                clienti pubblici citiamo: Ministero del Lavoro e delle Politiche
                Sociali, Ispettorato Nazionale del Lavoro, Ministero delle
                Infrastrutture e Trasporti, Presidenza Consiglio dei Ministri,
                FormezPA, Aria (ex Lombardia Informatica), Intercent-ER, Regione
                Lazio, Regione Sardegna, Regione Sicilia, Regione Veneto, ecc.
                In particolare, presso molti dei clienti indicati, Deloitte è
                l’Advisor sulle tematiche di{" "}
                <b>ICT Strategy, Innovazione e Digital Transformation</b>,
                grazie alle competenze maturate e all’approccio
                multidisciplinare che coniuga tutte le principali dimensioni di
                business (persone, cultura, organizzazione, processi e
                tecnologia).
              </p>
              <br></br>
              <img
                className="img img-fluid"
                src={"./assets/icons/commedia-Logo.png"}
                height={10}
                alt="commediaLogo"
              />
              <p className="text-justify">
                Con significative expertise in progettazione e sviluppo di
                comunicazione e applicazione web e competenze specifiche nella
                comunicazione e nella gestione di progetti complessi Co.M.Media
                approccia le esigenze del cliente in modo strategico e
                innovativo per standard qualitativi e con una grande
                flessibilità operativa. Co.M.Media opera nel campo
                dell’E-Business e dell’Information & Communication Technology,
                sfruttando l’innovazione tecnologica per dotare soggetti
                pubblici e privati di soluzioni integrate e modulari che
                supportano i processi aziendali, la comunicazione interna ed
                esterna, la gestione delle attività.
              </p>
              <p className="text-justify">
                Co.M.Media progetta, realizza e fornisce strumenti, prodotti e
                servizi in grado di generare visibilità e sostenere le strategie
                integrate di comunicazione attraverso l’utilizzo efficace e
                produttivo della comunicazione multimediale, in linea con le
                sempre crescenti esigenze di interattività e ai nuovi paradigmi
                del web.
              </p>
            </div>
            <div className="col-12 text-left"></div>
          </div>
        </div>
      </div>
    </>
  );
}
