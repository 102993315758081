import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  UncontrolledCarousel,
} from "reactstrap";

const items = [
  {
    src: "/assets/ufficio-1.jpg",
    title: "L'Accordo Quadro",
    altText: "Slide 1",
    caption:
      "I servizi di supporto per l'adozione del cloud in un modello di erogazione pubblico",
    url: "/aq",
  },
  {
    src: "/assets/Carousel2.png",
    title: "Come Aderire",
    altText: "Slide 2",
    caption:
      "I servizi di supporto per l'adozione del cloud in un modello di erogazione pubblico",
    url: "/come-aderire",
  },
];

const CarouselConsip = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides = items.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption
          captionText={item.caption}
          captionHeader={item.caption}
        />
      </CarouselItem>
    );
  });
  const heros = items.map((item, index) => {
    return (
      <CarouselItem key={index}>
        <div className="item it-hero-wrapper it-dark it-overlay text-left">
          {/* img */}
          <div className="img-responsive-wrapper">
            <div className="img-responsive">
              <div className="img-wrapper">
                <img
                  src={process.env.PUBLIC_URL + item.src}
                  title="img title"
                  alt="imagealt"
                />
              </div>
            </div>
          </div>
          {/* texts */}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-hero-text-wrapper bg-dark">
                  <span className="it-category"></span>
                  <h1 className="no_toc">{item.title}</h1>
                  <p className="d-none d-lg-block">{item.caption}</p>
                  <div className="it-btn-container">
                    <a
                      className="btn btn-sm px-5 btn-secondary"
                      href={item.url}
                    >
                      Scopri
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CarouselItem>
    );
  });
  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      <CarouselIndicators
        items={items}
        activeIndex={activeIndex}
        onClickHandler={goToIndex}
      />
      {heros}
    </Carousel>
    // <UncontrolledCarousel controls={false} items={items} />
  );
};

export default CarouselConsip;
