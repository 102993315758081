import {
  Header,
  Icon,
  Button,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  HeaderRightZone,
  HeaderBrand,
  HeaderContent,
  AvatarIcon,
} from "design-react-kit";
import "./Components.css";

import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../redux/actions/user";

import { useHistory } from "react-router-dom";
import HeaderComponent from "./HeaderComponent";
// import { Nav, NavItem, NavLink } from "reactstrap";

export default function ConsipHeader() {
  let history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  const handleLogoutClick = () => {
    dispatch(userLogout());
  };

  return (
    <>
      <Header className="remove-nav" small={false} theme="" type="slim">
        <HeaderContent>
          {user?.user === null || user?.user === undefined ? (
            <HeaderBrand></HeaderBrand>
          ) : (
            <HeaderBrand responsive={false} tag="a">
              Ente appartenenza: <b>{" " + user?.selectedEntityName}</b>
            </HeaderBrand>
          )}
          <HeaderRightZone>
            <div className="it-access-top-wrapper remove-nav">
              {user?.user === null || user?.user === undefined ? (
                <Button
                  color="primary"
                  icon={false}
                  size="sm"
                  tag="button"
                  onClick={() => history.push("/login")}
                >
                  Accedi
                </Button>
              ) : (
                <UncontrolledDropdown tag="div">
                  <DropdownToggle
                    aria-haspopup
                    color="secondary"
                    nav
                    className="remove-nav"
                  >
                    <span className="">
                      {user.name} {user.surname}
                    </span>
                    <AvatarIcon className="remove-nav" size="" tag="div">
                      <Icon
                        color=""
                        className="remove-nav"
                        icon="it-user"
                        padding={false}
                        size=""
                      />
                    </AvatarIcon>
                    {/* <Icon color="" icon="it-expand" padding={false} size="" /> */}
                  </DropdownToggle>
                  <DropdownMenu flip tag="div">
                    <div>
                      <div onClick={handleLogoutClick}>
                        <span>Logout</span>
                      </div>
                    </div>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </div>
          </HeaderRightZone>
        </HeaderContent>
      </Header>
      <div className="it-nav-wrapper remove-nav">
        <Header className="remove-nav" small={false} theme="" type="center">
          <HeaderContent>
            <div
              className="pe-auto remove-nav"
              onClick={() => {
                history.push("/home");
              }}
            >
              <HeaderBrand
                className="remove-nav"
                iconName=""
                responsive={false}
                tag="a"
              >
                <div className="row">
                  <div className="col-2">
                    <img
                      alt=""
                      height="100"
                      src={process.env.PUBLIC_URL + "/assets/icons/portal.png"}
                    />
                  </div>
                  <div className="col-7">
                    <h3 className="text-left">
                      Accordo Quadro Servizi Cloud IaaS e PaaS &ensp;&ensp; PAL
                      SUD &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&emsp;
                    </h3>
                    <h2 className="text-left">Portale della fornitura</h2>
                  </div>
                </div>
              </HeaderBrand>
            </div>
          </HeaderContent>
        </Header>
        <Header small={false} theme="" type="navbar">
          <HeaderComponent></HeaderComponent>
        </Header>
      </div>
    </>
  );
}
